const Fade = ({fadeOut = null}) => {
  return <>
    <style>
      {`
        @keyframes move-bg {
          0% {
            opacity: 0; 
          } 20% { 
            opacity: 0.8;
          } 80% {
            opacity: 0.8;
          } 100% {
            opacity: 0;
          }            
        }
        .fade-out {
          animation: move-bg 2s;
          z-index: 1000 !important;
        }
      `}
    </style>
    <div className={fadeOut ? 'fade-out' : ''} style={styles.fadeBlock}></div>
  </>
}

const styles = {
  fadeBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000',
    opacity: 0,
    zIndex: 0,
  },
}

export default Fade
