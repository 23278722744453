import {useEffect, useState} from 'react'
import Auth from './components/Auth'
import Game from './components/Game'
import logger from './utils/logger'
import io from 'socket.io-client'
import Lobby from './components/Lobby'
import {Container} from 'react-bootstrap'

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [socket, setSocket] = useState()

  const [gameState, setGameState] = useState({characters: [], npcs: []})
  const [characterId, setCharacterId] = useState()

  const handleLogout = () => {
    logger.log('App :: logout')

    localStorage.removeItem('token')
    setToken(null)

    if (socket) {
      socket.removeAllListeners()
      socket.disconnect()
      setSocket(null)
    }
  }

  const handleAuthSuccess = (token) => {
    logger.log('App :: INIT :: creating io :: token = ' + token)
    const s = io(process.env.REACT_APP_API_URL, {
      reconnection: false, autoConnect: false, query: {token}
    })

    s.on('connect', () => {
      logger.log('App :: ON :: connect :: ' + s.id)
    })

    s.on('disconnect', () => {
      logger.log('App :: ON :: disconnect')
      handleLogout()
    })

    s.on('connect_error', (err) => {
      logger.log('App :: ON :: connect_error :: ' + err.message)
      handleLogout()
    })

    s.on('enterWorld', ({characterId}) => {
      logger.log('App :: ON :: enterWorld')
      setCharacterId(characterId)
    })

    s.on('gameUpdate', (gameState) => {
      logger.log('App :: ON :: gameUpdate')
      setGameState(gameState)
    })

    if (!s.connected) {
      logger.log('App :: INIT :: connecting')
      s.connect()
    }

    setSocket(s)
    setToken(token)
  }

  useEffect(() => {
    if (token && !socket) {
      logger.log('App :: attempt to reconnect')
      handleAuthSuccess(token)
    }
  }, [])

  return (<Container fluid className="vh-100">
      {!token && <Auth onAuthSuccess={handleAuthSuccess}/>}
      {token && socket && !characterId && <Lobby socket={socket} onLogout={handleLogout}/>}
      {token && socket && characterId && <Game socket={socket} gameState={gameState} characterId={characterId}/>}
    </Container>)
}

export default App
