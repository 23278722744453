import 'bootstrap/dist/css/bootstrap.min.css'
import {useState} from 'react'
import axios from 'axios'
import {Row, Col, Form, Button, Alert, InputGroup, Image} from 'react-bootstrap'
import logo from '../assets/images/logo.jpg'

const Auth = ({onAuthSuccess}) => {
  const [email, setEmail] = useState(localStorage.getItem('last_email') || '')
  const [password, setPassword] = useState('')
  const [validated, setValidated] = useState(false)
  const [error, setError] = useState('')

  const handleAuthSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    const form = event.currentTarget
    if (form.checkValidity() !== false) {
      try {
        setError('')
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/auth', {email, password})
        const {token} = response.data
        localStorage.setItem('token', token)
        localStorage.setItem('last_email', email)
        onAuthSuccess(token)
      } catch (err) {
        setError('Authentication failed')
      }
    }
  }

  return (
    <>
      <Row className="justify-content-center text-center mb-4">
        <Col md={4}>
          <Image className="w-100 my-3 my-lg-4" src={logo} alt="Hard Dwarf Life" rounded/>
          <h2>Login or Register</h2>
        </Col>
      </Row>

      {error && <Row className="mb-4 justify-content-center"><Col md={4}><Alert variant="danger">{error}</Alert></Col></Row>}

      <Form noValidate validated={validated} onSubmit={handleAuthSubmit}>
        <Row className="justify-content-center">
          <Form.Group as={Col} md={4} className="mb-4" controlId="formBasicUsername">
            <Form.Label className="m-0">Email*</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid email</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>

        <Row className="justify-content-center">
          <Form.Group as={Col} md={4} className="mb-4" controlId="formBasicPassword">
            <Form.Label className="m-0">Password*</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">Please choose a password</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col md={4}>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Auth
