import Item from './Item'

const Inventory = ({inventory, showItemModal}) => {
  return (
    <>
      {inventory ? Object.values(inventory).map((item) => (
        <Item
          key={item._id}
          item={item}
          actions={{
            equip: 'Equip',
            drop: 'Drop',
          }}
          showItemModal={showItemModal}
        />
      )) : <small>empty</small>}
    </>
  )
}

export default Inventory
