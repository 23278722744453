import {Button, Image} from 'react-bootstrap'
import attack from '../../assets/images/attack.png'

const Abilities = ({socket, target, isActionsBlocked, handleBlockActions}) => {
  const onAttackClick = () => {
    handleBlockActions()
    socket.emit('attack', {target})
  }

  return (
    <div className="d-flex align-items-center justify-content-center" style={styles.abilities}>
      <style>
        {`
          .animated-cd {
            position: relative;
          }
          .animated-cd::before, .animated-cd::after {
            display: block;
            content: ' ';
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: none;
            position: absolute;
            width: 68px;
            height: 68px;
          }
          .animated-cd::before {
            top: -3px;
            left: -3px;
          }
          .animated-cd::after {
            bottom: -3px;
            right: -3px;
            opacity: 1;
          }
          .animated-cd.start-animation::before {
            width: 0;
            height: 0;
            border-top: 3px solid #dde0e1;
            border-right: 3px solid #dde0e1;
            transition: width 500ms 1500ms linear, height 500ms 1000ms linear;
          }
          .animated-cd.start-animation::after {
            width: 0;
            height: 0;
            border-bottom: 3px solid #dde0e1;
            border-left: 3px solid #dde0e1;
            opacity: 0;
            transition: width 500ms 500ms linear, height 500ms 0ms linear, opacity 0ms 1000ms linear;
          }
        `}
      </style>

      <Button
        variant="light"
        type="button"
        size="lg"
        onClick={() => onAttackClick()}
        disabled={isActionsBlocked}
        style={styles.ability}
        className={'animated-cd ' + (isActionsBlocked ? 'start-animation' : '')}
      >
        <Image src={attack} width={30}/>
      </Button>
    </div>
  )
}

const styles = {
  abilities: {
    position: 'absolute',
    bottom: 100,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 200,
    height: 80,
    fontSize: '9px',
    textAlign: 'center',
    borderRadius: 10,
    opacity: 0.85,
    background: '#919995',
  },
  ability: {
    borderRadius: 32,
    height: 64,
  }
}

export default Abilities
