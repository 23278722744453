import {Button} from 'react-bootstrap'

const Navigation = ({socket, routes, isActionsBlocked, handleBlockActions, handleMoveAnimation}) => {
  const onRouteClick = (route) => {
    handleBlockActions()
    handleMoveAnimation(route)
    socket.emit('move', {dir: route})
  }

  return (
    <>
      <style>
        {`
          .route-button {
            position: absolute;
          }
          .route-north {
            top: 5px;
            left: 50%;
            transform: translateX(-50%);            
          }
          .route-west {
            bottom: 50%;
            left: 5px;
            transform: translateY(-50%);               
          }
          .route-east {
            bottom: 50%;
            right: 5px;
            transform: translateY(-50%);    
          }
          .route-south {
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);    
          }
        `}
      </style>
      {routes.map((route) => (
        <Button
          key={route}
          variant="light"
          type="button"
          size="sm"
          onClick={() => onRouteClick(route)}
          disabled={isActionsBlocked}
          className={'route-button route-' + route}
        >
          {routeToArrow(route)}
        </Button>
      ))}
    </>
  )
}

const routeToArrow = (route) => {
  if (route === 'north') {
    return <>&uarr;</>
  } else if (route === 'west') {
    return <>&larr;</>
  } else if (route === 'east') {
    return <>&rarr;</>
  } else if (route === 'south') {
    return <>&darr;</>
  }
}

export default Navigation
