import {useEffect, useState} from 'react'
import logger from '../utils/logger'
import {Accordion, Badge, Col, ProgressBar, Row} from 'react-bootstrap'
import Equipment from './game/Equipment'
import Stats from './game/Stats'
import Inventory from './game/Inventory'
import ItemModal from './game/ItemModal'
import bg from '../assets/images/bg-dungeon.png';
import Navigation from './game/Navigation'
import Character from './game/Character'
import Item from './game/Item'
import Abilities from './game/Abilities'
import Fade from './game/Fade'

const GLOBAL_COOLDOWN = 2000

const Game = ({socket, gameState, characterId}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [showItemModal, setShowItemModal] = useState(false)
  const [itemToShow, setItemToShow] = useState({item: {}, actions: {}, position: {}})

  const [currentTarget, setCurrentTarget] = useState({type: 'none', id: null})
  const [isActionsBlocked, setIsActionsBlocked] = useState(false)
  const [moveAnimation, setMoveAnimation] = useState(null)

  const onLeaveWorld = () => {
    socket.emit('leaveWorld')
  }

  const handleShowItemModal = (item, actions, position) => {
    setShowItemModal(true)
    setItemToShow({item, actions, position})
    setCurrentTarget({type: 'item', id: item._id})
  }

  const handleHideItemModal = () => {
    setShowItemModal(false)
    setItemToShow({item: {}, actions: {}, position: {}})
    setCurrentTarget({type: 'none', id: null})
  }

  const handleBlockActions = () => {
    setIsActionsBlocked(true)
    setTimeout(() => {
      setIsActionsBlocked(false)
    }, GLOBAL_COOLDOWN)
  }

  const handleMoveAnimation = (dir) => {
    setMoveAnimation(dir)
    setTimeout(() => {
      setMoveAnimation(null)
    }, GLOBAL_COOLDOWN)
  }

  const {room, character} = findCharacterAndRoom(Object.values(gameState.map), characterId)

  useEffect(() => {
    socket.on('actionRejected', ({message}) => {
      logger.log('Game :: ON :: actionRejected')
      setErrorMessage(message)
      setTimeout(() => setErrorMessage(''), GLOBAL_COOLDOWN)
    })

    return () => {
      socket.off('actionRejected')
    }
  }, [])

  return (
    <>
      <style>
        {`
          .accordion-button:focus {
            box-shadow: none !important;
          }
          .game-area {
            min-height: 780px;
            max-height: 900px;
          }

          @keyframes move-north {
            0% {
              top: 0;
              opacity: 1; 
            } 50.001% { 
              top: -300px;
              opacity: 0;
              top: 0 !important;
            } 50.002% {
              top: 300px;
              opacity: 0;
            } 100% {
              top: 0;
              opacity: 1;
            }
          }
          @keyframes move-south {
            0% {
              top: 0;
              opacity: 1; 
            } 50.001% { 
              top: 300px;
              opacity: 0;
              top: 0 !important;
            } 50.002% {
              top: -300px;
              opacity: 0;
            } 100% {
              top: 0;
              opacity: 1;
            }
          }
          @keyframes move-west {
            0% {
              left: 0;
              opacity: 1; 
            } 50.001% { 
              left: -300px;
              opacity: 0;
              top: 0 !important;
            } 50.002% {
              left: 300px;
              opacity: 0;
            } 100% {
              left: 0;
              opacity: 1;
            }
          }
          @keyframes move-east {
            0% {
              left: 0;
              opacity: 1; 
            } 50.001% { 
              left: 300px;
              opacity: 0;
              top: 0 !important;
            } 50.002% {
              left: -300px;
              opacity: 0;
            } 100% {
              left: 0;
              opacity: 1;
            }
          }
          .move-north {
            animation: move-north 2s;
          }
          .move-south {
            animation: move-south 2s;
          }
          .move-west {
            animation: move-west 2s;
          }
          .move-east {
            animation: move-east 2s;
          }
          @media only screen and (max-width: 600px) {
            .game-area {
              min-height: 500px;
              max-height: 700px;
            }
          }
        `}
      </style>
      <Row className="vh-100">
        <Col lg={3} className="my-3 my-lg-4">
          <div className="text-center">
            {character.race || ''} {character.gameClass || ''} <strong>{character.name}</strong>
            <br/>
            <small>Level {character.level || 1}</small>
            <ProgressBar now={23} className="mb-4"/>
          </div>

          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Stats</Accordion.Header>
              <Accordion.Body className="py-2">
                <Stats stats={character.stats}/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Equipment</Accordion.Header>
              <Accordion.Body className="py-2">
                <Equipment equipment={character.equipment} showItemModal={handleShowItemModal}/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Inventory</Accordion.Header>
              <Accordion.Body className="py-2">
                <Inventory inventory={character.inventory} showItemModal={handleShowItemModal}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col lg={6} className="my-3 my-lg-4">
          <h5 className="text-center">
            {room.name}
            {/*<Button variant="secondary" type="button" onClick={onLeaveWorld} className="mb-2 float-end">Leave World</Button>*/}
          </h5>
            <div className="d-flex justify-content-center">
              <div className="game-area" style={styles.gameArea}>
                <Fade fadeOut={moveAnimation} />
                <div style={styles.gameBg}></div>

                <div style={styles.gameContent}>
                  <Navigation
                    socket={socket}
                    routes={Object.keys(room.routes)}
                    isActionsBlocked={isActionsBlocked}
                    handleBlockActions={handleBlockActions}
                    handleMoveAnimation={handleMoveAnimation}
                  />
                  {currentTarget.id !== null &&
                    <Abilities
                      socket={socket}
                      target={currentTarget}
                      isActionsBlocked={isActionsBlocked}
                      handleBlockActions={handleBlockActions}
                    />
                  }
                  {errorMessage && <Badge bg="danger" style={styles.badge}>{errorMessage}</Badge>}

                  <div style={styles.charactersArea}>
                    <Row className="justify-content-center">
                      {Object.values(room.creatures).map(creature => (
                        <Col lg={3} md={4} xs={6} key={creature._id}>
                          <Character
                            character={creature}
                            isSelected={currentTarget.type === 'character' && currentTarget.id === creature._id}
                            setTarget={() => {
                              setCurrentTarget({type: 'character', id: creature._id})
                            }}
                            moveAnimation={creature._id === characterId ? moveAnimation : null}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col className="text-center">
                        {Object.values(room.items).map((item) => (
                          <Item
                            key={item._id}
                            item={item}
                            actions={{
                              equip: 'Equip',
                              pick: 'Pick',
                            }}
                            showItemModal={handleShowItemModal}
                          />
                        ))}
                      </Col>
                    </Row>
                  </div>

                  <div className="enemies-area"></div>

                </div>
            </div>
          </div>
        </Col>
        <Col lg={3} className="my-3 my-lg-4">
          <h3>Map</h3>
        </Col>
      </Row>

      <ItemModal
        socket={socket}
        show={showItemModal}
        item={itemToShow.item}
        actions={itemToShow.actions}
        position={itemToShow.position}
        handleClose={handleHideItemModal}
        isActionsBlocked={isActionsBlocked}
        handleBlockActions={handleBlockActions}
      />
    </>
  )
}

const findCharacterAndRoom = (rooms, characterId) => {
  for (let i = 0; i < rooms.length; i++){
    const room = rooms[i]
    const character = Object.values(room.creatures).find((cr) => cr._id === characterId);
    if (character) {
      return {room, character}
    }
  }
  return {}
}

const styles = {
  charactersArea: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  gameBg: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    filter: 'blur(3px)',
    opacity: 0.8,
  },
  gameContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
  gameArea: {
    position: 'relative',
    width: '100%',
  },
  badge: {
    position: 'absolute',
    top: 100,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 10,
    opacity: 0.7,
  }
}

export default Game
