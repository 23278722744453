import {Button} from 'react-bootstrap'
import {useRef} from 'react'

const Item = ({item, actions, showItemModal}) => {
  const ref = useRef()

  return <Button
    key={item._id}
    variant={qualityToClass(item.quality)}
    type="button"
    size="sm"
    className="me-1 mb-1 ps-1 py-0 position-relative"
    onClick={() => showItemModal(item, actions, {
      left: ref.current.getBoundingClientRect().left,
      top: ref.current.getBoundingClientRect().top,
    })}
    ref={ref}
  >
    <sup>{item.level}</sup> {item.name}
  </Button>
}

const qualityToClass = (quality) => {
  if (quality === 0) {
    return 'light'
  } else if (quality === 1) {
    return 'warning'
  } else if (quality === 2) {
    return 'info'
  } else if (quality === 3) {
    return 'success'
  } else if (quality === 4) {
    return 'danger'
  }
}

export default Item
