const statToText = (stat) => Array.isArray(stat)
    ? `${Number.parseFloat((stat[0] / 100).toFixed(2))}-${Number.parseFloat((stat[1] / 100).toFixed(2))}`
    : Number.parseFloat((stat / 100).toFixed(2))

const getUnits = (stat) => {
    let unit = ''
    switch (stat) {
      case 'critical':
      case 'spell critical':
      case 'dodge':
      case 'parry':
      case 'block chance':
      case 'magic resistance':
      case 'accuracy':
      case 'damage bonus':
      case 'spell damage bonus':
      case 'magic find':
      case 'earth resistance':
      case 'fire resistance':
      case 'water resistance':
      case 'air resistance':
      case 'damage reduction':
      case 'spell penetration':
      case 'armor penetration':
        unit = '%'
        break

      case 'hp regeneration':
      case 'mana regeneration':
        unit = 'per 3s'
        break

      default:
        unit = ''
    }
    return unit
  }

const statsToIgnore = ['max hp', 'max mana']

const getPrimaryPercent = (character, stat) => {
  const current = character.stats.primary[stat]
  const max = character.stats.secondary['max ' + stat]
  return 100 * current / max
}

const getPrimaryText = (character, stat) => {
  const current = Math.floor(character.stats.primary[stat] / 100)
  const max = Math.floor(character.stats.secondary['max ' + stat] / 100)
  return `${current} / ${max}`
}

export {statToText, getUnits, statsToIgnore, getPrimaryPercent, getPrimaryText}
