import {ListGroup} from 'react-bootstrap'
import {getUnits, statsToIgnore, statToText} from '../../utils/stats'

const Stats = ({stats}) => {
  return (
    <ListGroup>
      <style>
        {`
          .stats-list-item {
            font-size: 0.875rem;
            border-bottom: 1px solid #dcdcdc;
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .stats-list-item:last-child {
            border-bottom: none;
          }
        `}
      </style>

      {Object.keys(stats.secondary)
        .filter((stat) => !statsToIgnore.includes(stat))
        .sort((a, b) => sortWeights[a] > sortWeights[b])
        .map((key) => (
          <ListGroup.Item key={key} className="stats-list-item p-0 d-flex align-items-center justify-content-between">
            <small>{key}</small>
            <small>{statToText(stats.secondary[key])} {getUnits(key)}</small>
          </ListGroup.Item>
        ))}
    </ListGroup>
  )
}

const sortWeights = {
  'max hp': 0,
  'max mana': 1,
  damage: 2,
  critical: 3,
  'spell damage': 4,
  'spell critical': 5,
  armor: 6,
  dodge: 7,
  parry: 8,
  'block chance': 9,
  'block value': 10,
  'magic resistance': 11,
  accuracy: 12,
  'attack speed': 13,
  'damage bonus': 14,
  'casting speed': 15,
  'spell damage bonus': 16,
  'hp regeneration': 17,
  'mana regeneration': 18,
  'skill bonus': 19,
  'magic find': 20,
  'earth resistance': 21,
  'fire resistance': 22,
  'water resistance': 23,
  'air resistance': 24,
  'sword damage': 25,
  'axe damage': 26,
  'dagger damage': 27,
  'polearm damage': 28,
  'mace damage': 29,
  'earth spell damage': 30,
  'fire spell damage': 31,
  'water spell damage': 32,
  'air spell damage': 33,
  'damage reduction': 34,
  'spell penetration': 35,
  'armor penetration': 36,
}

export default Stats
