import {Button, ProgressBar} from 'react-bootstrap'
import {getPrimaryPercent, getPrimaryText} from '../../utils/stats'

const Character = ({character, isSelected, setTarget, moveAnimation}) => {
  return <Button
    className={'m-1 p-0' + (moveAnimation !== null ? ' move-' + moveAnimation : '')}
    variant="secondary"
    type="button"
    onClick={setTarget}
    style={{
      ...styles.character,
      border: isSelected ? '2px solid rgb(101, 241, 255)' : 'none',
    }}
  >
    <ProgressBar
      now={getPrimaryPercent(character, 'hp')}
      label={getPrimaryText(character, 'hp')}
      variant="success"
      className="mb-1"
      style={styles.progress}
    />
    <div className="pb-1">
      {character.race || ''} {character.gameClass || ''}<br/>
      <strong
        style={styles.characterName}>{character.name}</strong>
    </div>
    <ProgressBar
      now={getPrimaryPercent(character, 'mana')}
      label={getPrimaryText(character, 'mana')}
      className="mt-2"
      style={styles.progress}
    />
  </Button>
}

const styles = {
  character: {
    width: '100px',
    fontSize: '9px',
    textAlign: 'center',
    position: 'relative',
  },
  characterName: {
    fontSize: '13px',
  },
  progress: {
    fontSize: '8px',
    height: '0.5rem',
    color: '#000',
  }
}

export default Character
